import React, { useEffect, useState } from 'react'
import Lottie from "lottie-react"
import { useParams } from 'react-router-dom'

/** Launch screen UI */
export const LaunchScreen = props => {

    // App state
    let [ errorText, setErrorText ] = useState('')

    // Dynamically fetch images
    let [ serverImage, setServerImage ] = React.useState(null)
    let [ errorImage, setErrorImage ] = React.useState(null)
    React.useEffect(() => {
        import('../Images/server.lottie.json').then(image => setServerImage(image))
        import('../Images/error.lottie.json').then(image => setErrorImage(image))
    }, [])

    // Get router info
    let { name, branch } = useParams()

    /** Status check function */
    let checkStatus = async () => {

        // Catch errors
        try {

            // Fetch access info
            let json = await fetch('/api/app/start', {
                method: 'POST',
                body: JSON.stringify({ name, branch }),
            }).then(r => r.json())

            // Store error state
            setErrorText(json.error || '')

        } catch (err) {

            // Show error
            console.error(err)
            setErrorText(err.message)

        }

    }

    /** Create a timer to call the function every 15 seconds */
    useEffect(() => {
        checkStatus()
        // let timer = setInterval(checkStatus, 15000)
        return () => clearInterval(timer)
    }, [])

    // Render UI
    return <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', textAlign: 'center', color: '#DDD' }}>

        {/* Logo */}
        {errorText
            ? <Lottie animationData={errorImage} loop={false} style={{ width: 400, height: 400, margin: -100 }} />
            : <Lottie animationData={serverImage} loop={true} style={{ width: 200, height: 200, margin: 0 }} />
        }

        {/* Text */}
        <div style={{ margin: '40px 20px 0px 20px', fontSize: 24 }}>{errorText ? 'There was an error' : 'Preparing app'}</div>
        <div style={{ margin: '10px 20px 0px 20px', fontSize: 12 }}>{errorText ? errorText : 'Please wait, this could take a few minutes...'}</div>

    </div>

}