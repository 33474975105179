import React, { useEffect } from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter, Route, Routes } from "react-router-dom"
import { LaunchScreen } from './Routes/LaunchScreen'

// Main app component
const App = props => {

    // Render UI
    return <div>

        {/* App routes */}
        <BrowserRouter>
            <Routes>
                <Route path="/launch/:name" element={<LaunchScreen />} />
                <Route path="/launch/:name/:branch" element={<LaunchScreen />} />
                <Route path="*" element={<h1>404 Not Found</h1>} />
            </Routes>
        </BrowserRouter>

    </div>

}

// Render the app
ReactDOM.createRoot(document.getElementById('app')).render(<App />)